import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SellerService } from '../services/seller.service';

@Component({
  selector: 'app-seller-dashboard',
  templateUrl: './seller-dashboard.component.html',
  styleUrls: ['./seller-dashboard.component.css']
})
export class SellerDashboardComponent implements OnInit {

  constructor(private sellerService: SellerService, private router: Router) { }
  allSellers = [];
  ngOnInit(): void {
    this.getAllSellers();
  }

  async getAllSellers(): Promise<void> {
    const result = await this.sellerService.getAllSellers();
    if (result.status) {
      this.allSellers = result.rows;
    } else {
      alert(result.message);
    }
  }

  viewSeller(sellerUserID: string): void {
    this.router.navigate(['/seller/view', sellerUserID]);
  }

  async approveSeller(sellerUserID: string): Promise<void> {
    const result = await this.sellerService.approveSeller(sellerUserID);
    if (result.status) {
      alert('Seller approved successfully');
      this.getAllSellers();
    } else {
      alert(result.message);
    }
  }

}
