// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDle8VErElL5lOsjS_Ps6bakzHm-kUgqx0',
    authDomain: 'oauth.crepme.com',
    projectId: 'possible-bee-310309',
    storageBucket: 'possible-bee-310309.appspot.com',
    messagingSenderId: '261347159864',
    appId: '1:261347159864:web:0c3937e5d150e515b0dbcd',
    measurementId: 'G-R14R4GTJQZ'
  },
  tenantId: 'TestUsers-opvez',
  backends: {
    main: 'https://adminapitest.crepme.com'
  },
  frontend: 'https://test-crepme.firebaseapp.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
