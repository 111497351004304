import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'crepme-admin';
  constructor(private auth: AngularFireAuth, private router: Router) {}

  ngOnInit(): void {
    this.auth.authState.subscribe(async user => {
      if (user) {
        if (!localStorage.getItem('role')) {
          alert('Your user role is missing. Please login again');
          await this.auth.signOut();
        }
        localStorage.setItem('token', await user.getIdToken());
      } else {
        console.log('not logged in');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
  }
}
