<div class="nav-mobile" (click)="showNav()">
    <span class="hamburger"></span>
    <span class="hamburger"></span>
</div>

<div [ngClass]="(responsive.active ? 'left show' : 'left hide')">

    <div class="left">
    
        <div class="navbar">
            <ul class="top-nav">
                <p>Name: {{userName}}</p>
                <p>Role: {{assignedRole}}</p>
                <p>Email: {{userEmail}}</p>
        
                <a routerLink="/products" routerLinkActive="router-link-active" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
                    <i class="fas fa-shield-alt"></i> <span class="nav-text">Products</span>
                </a>
                <a routerLink="/orders" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
                    <i class="fas fa-shopping-bag"></i> <span class="nav-text">Orders</span>
                </a>
                <a routerLink="/users" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
                    <i class="far fa-money-bill-alt"></i> <span class="nav-text">Users</span>
                </a>
                <a routerLink="/server" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
                    <i class="far fa-address-card"></i> <span class="nav-text">Server</span>
                </a>
                <a routerLink="/stripe" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
                    <i class="fas fa-chart-pie"></i> <span class="nav-text">Stripe</span>
                </a>
                <a routerLink="/pages" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
                    <i class="fas fa-cog"></i> <span class="nav-text">Pages</span>
                </a>
                <a routerLink="/seller" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
                    <i class="fas fa-cog"></i> <span class="nav-text">Seller Dashboard</span>
                </a>
                <a (click)="logout()">
                    <i class="fas fa-sign-out-alt"></i> <span class="nav-text">Logout</span>
                </a>
        
            </ul>
           
        </div>
    </div>
</div>