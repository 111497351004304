import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProductsService } from '../services/products.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  products = [];
  page = 1;
  limit = 10;
  totalProducts = 0;
  totalPages = [];

  constructor(private productService: ProductsService) { }

  ngOnInit(): void {
    this.getAllProducts();
  }

  async getAllProducts(): Promise<void> {
    const result = await this.productService.getAllProducts(this.page, this.limit);
    this.products = result.products;
    this.totalProducts = result.total_count;
    this.totalPages = Array(Math.ceil(this.totalProducts / this.limit)).fill(1).map((x, i) => i + 1);
  }

  async syncSearch(): Promise<void> {
    await this.productService.syncSearch();
  }

  changePage(page: number, el: HTMLElement): void {
    this.page = page;
    el.scrollIntoView();
    this.getAllProducts();
  }

  goToWebsite(id: string): void {
    window.open(`${environment.frontend}/product/${id}`, '_blank');
  }

  async deleteProduct(id: string): Promise<void> {
    if (confirm('Are you sure you want to delete this product?')) {
      const result = await this.productService.deleteProduct(id);
      if (result.status) {
        this.getAllProducts();
      } else {
        alert('Error deleting product');
      }
    }
  }

}
