<div class="container">
    

     <app-navbar>

    </app-navbar>
 

    <div class="right">

    </div>
</div>

