<button>Back</button>

<div class="product-layout">
    <h4>Product Name</h4>
    <h5>SKU</h5>
    <h5>Colorway</h5>
    <h5></h5>
    <img src="">
    <div class="description">
        <p></p>
    </div>

    <button>Update Info</button>
    <button>Update prize</button>

    
</div>