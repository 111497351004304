import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonResponse } from '../modals/common.modal';
import { AllOrders, PDFCodeResponse, QRCodeResponse, SingleOrder } from '../modals/orders.modal';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient) { }

  async getAllOrders(): Promise<AllOrders> {
    return this.http.get<AllOrders>(environment.backends.main + '/orders/all', {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise();
  }

  async getOrder(orderId: string): Promise<SingleOrder> {
    return this.http.get<SingleOrder>(environment.backends.main + '/orders/' + orderId, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise();
  }

  async orderReceived(orderId: string): Promise<CommonResponse> {
    return this.http.post<CommonResponse>(environment.backends.main + '/order/recieved', {
      order_id: orderId
    }, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise();
  }

  async orderApprove(orderId: string): Promise<CommonResponse> {
    return this.http.post<CommonResponse>(environment.backends.main + '/order/approved', {
      order_id: orderId
    }, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise();
  }

  async orderGetQRCode(orderId: string): Promise<QRCodeResponse> {
    return this.http.get<QRCodeResponse>(environment.backends.main + '/order/' + orderId + '/qrcode', {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise();
  }

  async orderGetPDF(orderId: string): Promise<PDFCodeResponse> {
    return this.http.get<PDFCodeResponse>(environment.backends.main + '/order/' + orderId + '/shippingpdf', {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise();
  }
}
