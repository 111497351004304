<div class="container">


    <app-navbar>
    </app-navbar>

    <div class="right">
        <button class="outline-btn">Back</button>
        <div class="header-options">   
            <h3>Seller Name</h3>

            <div class="button-options">
                <button class="red-btn">Disable</button>
                <button class="basic-btn">Onboard </button>
                <button class="basic-btn">Change Fee </button>
    
            </div>
        </div>
        <div class="info-block">
            <h4 class="sub-headline">Seller info</h4>
        
            <div class="profile-block">
                <div class="info-col">
                    <h3>Seller Fee</h3>
                    <p>9%</p>
                </div>
                <div class="info-col">
                    <h3>Total Sales</h3>
                    <p>300</p>
                </div>
                <div class="info-col">
                    <h3>Total Bids</h3>
                    <p>500</p>
                </div>
            </div>
            <div class="profile-block">
                <div class="info-col">
                    <h3>Current Fee</h3>
                    <p>9%</p>
                </div>
                <div class="info-col">
                    <h3>Total Points</h3>
                    <p>300</p>
                </div>
                <div class="info-col">
                    <h3>Total Bids</h3>
                    <p>500</p>
                </div>
            </div>
        </div>

        <div class="table-block" style="width: 99%; overflow-x: scroll;">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Current Withdrawal</th>
                        <th scope="col">Past Request</th>
                        
                        
    
                    </tr>
    
                </thead>
                <tbody>
                    <tr>
                        
                        
                        
                        
                        <td> 350aed</td>
                        <td> Purchased</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>