import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private auth: AngularFireAuth, private router: Router) { }

  ngOnInit(): void {
  }

  async loginWithMicrosoft(): Promise<void> {
    try {
      const provider = new firebase.auth.OAuthProvider('microsoft.com');
      provider.setCustomParameters({
        tenant: 'crepme.com'
      });
      const result = await this.auth.signInWithPopup(provider);
      const credential = result.credential;
      const creds: any = credential;
      const accessToken: string = creds.accessToken;
      const idToken: string = creds.idToken;
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(idToken);
      console.log(decodedToken);
      if (decodedToken?.roles?.length > 0) {
        localStorage.setItem('role', decodedToken.roles[0]);
        localStorage.setItem('idToken', idToken);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('token', await (await this.auth.currentUser).getIdToken());
        this.router.navigate(['/']);
      } else {
        console.log('No role found');
        alert('No role found. Please contact admin.');
        localStorage.clear();
        this.auth.signOut();
      }
    } catch (error) {
      console.log(error);
      if (error.code === 'auth/account-exists-with-different-credential') {
        const pendingCred = error.credential;
        const email = error.email;
        const methods = await this.auth.fetchSignInMethodsForEmail(email);
        let selectedMethod = prompt('Please select a sign in method. Valid values are ' + methods.join(',') + ':', methods[0]);
        if (selectedMethod === null || selectedMethod === '') {
          selectedMethod = prompt('Please select a sign in method. Valid values are ' + methods.join(',') + ':', methods[0]);
        } else if (!methods.includes(selectedMethod)) {
          alert('Invalid selection');
          selectedMethod = prompt('Please select a sign in method. Valid values are ' + methods.join(',') + ':', methods[0]);
        }
        const provider = this.getProviderForProviderId(selectedMethod);
        const finalResult = await this.auth.signInWithPopup(provider);
        await finalResult.user.linkWithCredential(pendingCred);
      }
      alert(error.message);
    }
  }
  getProviderForProviderId(method: string): firebase.auth.GoogleAuthProvider {
    if (method === 'google.com') {
      return new firebase.auth.GoogleAuthProvider();
    } else {
      return null;
    }
  }

}
