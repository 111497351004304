import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonResponse } from '../modals/common.modal';
import { SellerList } from '../modals/seller.modal';

@Injectable({
  providedIn: 'root'
})
export class SellerService {

  constructor(private http: HttpClient) { }

  getAllSellers(): Promise<SellerList> {
    return this.http.get<SellerList>(environment.backends.main + '/sellers/all', {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise();
  }

  approveSeller(sellerUserID: string): Promise<CommonResponse> {
    return this.http.post<CommonResponse>(environment.backends.main + '/seller/approve', {
      seller_user_id: sellerUserID
    }, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise();
  }
}
