<div class="container">


    <app-navbar>
    </app-navbar>

    <div class="right">
        <div class="table-block" style="width: 99%; overflow-x: scroll;">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Status</th>
                        <th>Product ID</th>
                        <th>Buyer ID</th>
                        <th>Matched Sell ID</th>
                        <th>Sell Price</th>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let order of allOrders">
                        <td>{{order.order_id}}</td>
                        <td>{{order.status}}</td>
                        <td>{{order.product_id}}</td>
                        <td>{{order.buyer_user_id}}</td>
                        <td>{{order.seller_sell_id}}</td>
                        <td>{{order.sell_price | currency: 'AED'}}</td>
                        <td><a routerLink="/order/{{order.order_id}}">View Order</a><button (click)="matchOrder" *ngIf="order.status == 'MATCHED'">Approve</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>