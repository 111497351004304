<div class="container">


    <app-navbar>
    </app-navbar>

    <div class="right">

        <h3>Seller Dashboard</h3>
        <div class="table-block" style="width: 99%; overflow-x: scroll;">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Seller Name</th>
                        <th scope="col">Seller Email</th>
                        <th scope="col">Seller Approved</th>

                        <th scope="col">Seller Identification</th>

                        
                        <th scope="col">Options</th>

                    </tr>

                </thead>
                <tbody>
                    <tr *ngFor="let seller of allSellers">

                        <td>{{seller.user_details.name}}</td>
                        <td>{{seller.user_details.email}}</td>
                        <td>{{seller.account_verified == 0 ? 'No' : 'Yes'}}</td>
                        <td>ID Number: {{seller.identification_id}} | ID Expiry Date: {{seller.identification_expiry | date: 'shortDate'}}</td>
                        
                        <td>
                            <div class="button-container">
                                <button class="outline-btn" (click)="viewSeller(seller.user_id)">View</button>
                                <button class="basic-btn" (click)="approveSeller(seller.user_id)">Approve</button>
                                <button class="outline-btn" (click)="rejectSeller(seller.user_id)">Reject</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>