<div class="container">


    <app-navbar>
    </app-navbar>

    <div class="right" *ngIf="loaded">
        Order ID: {{order.order_id}}
        <button *ngIf="order.status == 'RECEIVED AT CREPME'" (click)="approve(order.order_id)">Approve</button>
        <button *ngIf="order.status == 'RECEIVED AT CREPME'">Reject</button>
        <button *ngIf="order.status == 'MATCHED'" (click)="received(order.order_id)">Recieved at CREPME</button>
        <ng-container *ngIf="order.status == 'SHIPPED'">
            <img src="{{qrcode}}" alt="QR Code">
            <iframe [src]="pdfUrl" width="500" height="500"></iframe>
        </ng-container>
        
    </div>
</div>