import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../services/orders.service';

@Component({
  selector: 'app-order-page',
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.css']
})
export class OrderPageComponent implements OnInit {
  order = {};
  loaded = false;
  qrcode = '';
  pdf = '';
  pdfUrl: SafeUrl;
  constructor(private orderService: OrdersService, private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  async ngOnInit(): Promise<void> {
    const orderId = this.route.snapshot.paramMap.get('orderId');
    const result = await this.orderService.getOrder(orderId);
    this.order = result.order;
    this.loaded = true;
    if (result.order?.status === 'SHIPPED') {
      const qrres = await this.orderService.orderGetQRCode(orderId);
      this.qrcode = qrres.qrcode;
      const pdfres = await this.orderService.orderGetPDF(orderId);
      this.pdf = pdfres.pdf;
      console.log(this.pdf);
      if (!this.pdf.includes('data:application/pdf;base64,')) {
        this.pdf = 'data:application/pdf;base64,' + this.pdf;
      }
      console.log(this.pdf);
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdf);
      console.log(this.pdfUrl);
    }
  }

  async received(orderId: string): Promise<void> {
    await this.orderService.orderReceived(orderId);
    this.ngOnInit();
  }

  async approve(orderId: string): Promise<void> {
    await this.orderService.orderApprove(orderId);
    this.ngOnInit();
  }

}
