import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seller-view',
  templateUrl: './seller-view.component.html',
  styleUrls: ['./seller-view.component.css']
})
export class SellerviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
