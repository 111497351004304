import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonResponse } from '../modals/common.modal';
import { AllProducts } from '../modals/product.modal';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  async getAllProducts(currentPage: number, limit: number): Promise<AllProducts> {
    return this.http.get<AllProducts>(environment.backends.main + '/products/all', {
      params: {
        page: currentPage.toString() || '1',
        limit: limit.toString() || '10'
      },
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise();
  }

  async syncSearch(): Promise<CommonResponse> {
    return this.http.get<CommonResponse>(environment.backends.main + '/products/search/sync', {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise();
  }

  async deleteProduct(id: string): Promise<CommonResponse> {
    return this.http.post<CommonResponse>(environment.backends.main + '/product/delete', {
      product_id: id
    }, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).toPromise();
  }
}
