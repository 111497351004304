import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAuthModule, PERSISTENCE, TENANT_ID } from '@angular/fire/auth';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SellerDashboardComponent } from './seller-dashboard/seller-dashboard.component';
import { ProductsComponent } from './products/products.component';
import { SellerviewComponent } from './seller-view/seller-view.component';
import { HttpClientModule } from '@angular/common/http';
import { ProductViewComponent } from './product-view/product-view.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderPageComponent } from './order-page/order-page.component';
import { NgxPaginationModule } from 'ngx-pagination';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    SellerDashboardComponent,
    ProductsComponent,
    SellerviewComponent,
    ProductViewComponent,
    OrderListComponent,
    OrderPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    NgxPaginationModule
  ],
  providers: [
    AngularFireAuthGuard,
    { provide: PERSISTENCE, useValue: 'session' },
    { provide: TENANT_ID, useValue: environment.tenantId }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
