import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private auth: AngularFireAuth, private router: Router) { }
  responsive = {
    active: false
  };
  assignedRole: string;
  userName: string;
  userEmail: string;

  async ngOnInit(): Promise<void> {
    this.auth.authState.subscribe(async user => {
      if (user) {
        if (!localStorage.getItem('role')) {
          alert('Your user role is missing. Please login again');
          await this.auth.signOut();
        }
        localStorage.setItem('token', await user.getIdToken());
        this.assignedRole = localStorage.getItem('role');
        if (!this.assignedRole) {
          this.logout();
        }
        const user1 = await this.auth.currentUser;
        this.userName = user1.displayName;
        this.userEmail = user1.email;
      } else {
        console.log('not logged in');
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
  }
  showNav(): void {
    this.responsive.active = true;
  }

  closeNav(): void {
    this.responsive.active = false;
  }

  async logout(): Promise<void> {
    await this.auth.signOut();
    localStorage.clear();
  }
}
