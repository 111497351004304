import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderPageComponent } from './order-page/order-page.component';
import { ProductsComponent } from './products/products.component';
import { SellerDashboardComponent } from './seller-dashboard/seller-dashboard.component';
import { SellerviewComponent } from './seller-view/seller-view.component';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToItems },
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'products',
    component: ProductsComponent,
  },
  {
    path: 'seller',
    component: SellerDashboardComponent,
  },
  {
    path: 'seller/view/:sellerUserId',
    component: SellerviewComponent,
  },
  {
    path: 'orders',
    component: OrderListComponent
  },
  {
    path: 'order/:orderId',
    component: OrderPageComponent
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
