<div class="container">


    <app-navbar>
    </app-navbar>

    <div class="right">

        <h3 class="header" #scroll>All Products - Showing Page {{page}} of {{totalPages.length}} - Products {{page === 1 ? 1 : (page -
            1) * limit + 1}} to {{page * limit}}</h3>
            <div class="options-bar">
                <button (click)="syncSearch()">Sync Search Index</button>

            </div>
        <div class="table-block" style="width: 99%; overflow-x: scroll;">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Image</th>
                        <th scope="col">Brand</th>
                        <th scope="col">Name</th>
                        <th scope="col">SKU</th>
                        <th scope="col">Colorway</th>
                        <th scope="col">Options</th>

                    </tr>

                </thead>
                <tbody>
                    <tr *ngFor="let product of products">

                        <td><img [src]="product.image_original" alt="{{product.name}}" width="100"></td>
                        <td><img [src]="product.brand_logo" alt="{{product.brand}}" width="100"></td>
                        <td width="300">{{product.name}}</td>
                        <td width="100">{{product.sku}}</td>
                        <td width="50">{{product.colorway}}</td>
                        <td class="table-view">
                            <div class="options-bar">
                                
                                <button (click)="goToWebsite(product.id)">View on website</button>
                                <button>View</button>
                                <button class="delete"  (click)="deleteProduct(product.id)">Delete</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div *ngFor="let page of totalPages | paginate: { itemsPerPage: 10, currentPage: page }">
            </div>
            <div class="pagination">
                <pagination-controls (click)="changePage(page, scroll)" (pageChange)="page = $event"></pagination-controls>

            </div>
        </div>
    </div>
</div>